// Regex used in type="email" from WHATWG

import { ERROR_MESSAGES } from 'components/Form/constants'

// https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type=email)
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

// Allows 9-15 numbers, separated anywhere by dashes or spaces
// Can be prepended by a plus sign
const PHONE_NUMBER_REGEX = /^\+?(\d[\s-]?){9,15}$/

export const email = (value: string): string | null => {
  if (!value) return null
  const isValid = EMAIL_REGEX.test(value.trim())
  return isValid ? null : 'Please enter a valid email address.'
}

export const phoneNumber = (value: string): string | null => {
  if (!value) return null
  const isValid = PHONE_NUMBER_REGEX.test(value.trim())
  return isValid ? null : 'Please enter a valid phone number.'
}

export const getValidationErrorMessage = (
  errors: Record<string, boolean>
): string => {
  const errorsToArray = Object.keys(errors)
  let message = 'Something went wrong on our side! Please try again'

  if (errorsToArray.length > 1) {
    message = 'Please re-enter your details and try again'
  } else if (errorsToArray.length === 1) {
    message = (ERROR_MESSAGES as Record<string, string>)[errorsToArray[0]]
  }

  return `${message}, or give us a ring on `
}

import React from 'react'
import styled from 'styled-components'
import {
  Button,
  Wrapper,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

const ArrowsWrapper = styled(Wrapper)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ArrowButton = styled(Button.Plain)<{ disabled: boolean }>`
  background-color: ${COLOR.BLACK};
  width: 56px;
  height: 77px;
  border-radius: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: ${COLOR.BLACK};
  }

  &:disabled {
    opacity: 50%;
  }
`

interface CarouselArrowsProps {
  index: number
  maxIndex: number
  handleClick: (index: number) => void
}

const CarouselArrows = ({
  index,
  maxIndex,
  handleClick,
}: CarouselArrowsProps): React.ReactElement => (
  <ArrowsWrapper>
    <ArrowButton onClick={() => handleClick(index - 1)} disabled={index < 1}>
      <ChevronLeftIcon color={COLOR.WHITE} size="L" />
    </ArrowButton>
    <ArrowButton
      onClick={() => handleClick(index + 1)}
      disabled={index >= maxIndex}
    >
      <ChevronRightIcon color={COLOR.WHITE} size="L" />
    </ArrowButton>
  </ArrowsWrapper>
)

export default CarouselArrows

import React from 'react'
import styled from 'styled-components'
import { Grid, H, P } from '@farewill/ui'
import AspectRatioWrapper from 'components/AspectRatioWrapper'

const StyledGrid = styled(Grid)`
  align-items: center;
`

const VIDEO_URL = 'https://www.youtube.com/embed/uM8dHcE56lQ'

const Video = () => (
  <StyledGrid>
    <Grid.Item spanFromL={4}>
      <H tag="h2" size="L" decorative>
        A look behind the scenes
      </H>
      <P>
        We visited John at his studio to understand more about his creative
        process for designing and making the urns.
      </P>
    </Grid.Item>
    <Grid.Item spanFromL={8}>
      <AspectRatioWrapper ratio={16 / 9}>
        <iframe
          title="Farewill TV ad on YouTube"
          src={VIDEO_URL}
          frameBorder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </AspectRatioWrapper>
    </Grid.Item>
  </StyledGrid>
)

export default Video

import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { H } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import QuestionList from 'components/FrequentlyAskedQuestions/QuestionList'

const StyledTCsContent = styled.div`
  ol {
    list-style: decimal;
    padding-inline-start: ${GTR.M};
    counter-reset: item;
  }

  li {
    counter-increment: item;
  }

  ol ol {
    list-style: none;

    li {
      margin-bottom: ${GTR.M};
    }

    li:before {
      content: counters(item, '.') ' ';
      font-variant: tabular-nums;
      text-indent: -32px;
      margin-left: -32px;
    }
  }
`

const TCsContent = () => (
  <StyledTCsContent>
    <H size="XS" tag="h4">
      Promotion Rules
    </H>
    <ol>
      <li>
        <H size="XS" tag="h5">
          General
        </H>
        <ol>
          <li>
            These rules and any other rules Farewill Limited{' '}
            <em>(Farewill, we, us, our)</em> specify from time to time{' '}
            <em>(Promotion Rules)</em> apply to any promotion which we run or is
            run by another organisation on our behalf <em>(promotion)</em>. Any
            decision we make in relation to a promotion is final and at our sole
            discretion, and we may decide to disqualify any entrant or winner
            for any reason without notice.
          </li>
          <li>
            By taking part in a promotion, you agree to be bound by the
            Promotion Rules and our decisions. It is your responsibility (as the
            entrant) to make sure you understand the Promotion Rules and keep
            yourself informed about any updates.
          </li>
        </ol>
      </li>
      <li>
        <H size="XS" tag="h5">
          Entry Rules
        </H>
        <ol>
          <li>
            All entries must be received at the place specified during the
            period which the promotion is open. Late or incomplete entries will
            be disqualified. The specified closing date and time of a promotion
            is in Greenwich Mean Time (GMT).
          </li>
          <li>
            Entries must be submitted by an individual located (not via any
            agency or similar) and, unless stated otherwise, are limited to one
            per household. Multiple entries are not permitted. Any entry which
            is (or we consider to be) corrupted, incomplete or offensive may be
            disqualified.
          </li>
          <li>
            Prizes non-exchangeable and non-transferable. They may be provided
            by a third-party sponsor of the promotion and we may sometimes
            decide to substitute any prize with cash or a prize of comparable
            value.
          </li>
          <li>
            Unless stated otherwise, each promotion is open to anyone aged 18
            years or older located in the United Kingdom, except employees of:
            a) Farewill; b) any third-party we appoint to help organise or
            manage the promotion; and c) the promotion sponsor(s).
          </li>
        </ol>
      </li>
      <li>
        <H size="XS" tag="h5">
          Your entry
        </H>
        <ol>
          <li>
            By entering a promotion, you:
            <br />
            a) grant Farewill, the promotion sponsor(s) and any third-party we
            have appointed to assist with the promotion to use your submitted
            details.
            <br />
            <br />
            b) accept that, once received by us, all entries become the property
            of Farewill and will not be returned. You will retain all rights you
            have in the copyright and other intellectual property rights
            comprising the promotion entry but, by entering the promotion, you
            grant us and our licensees a world worldwide, royalty-free,
            exclusive and irrevocable right to republish, amend and/or reproduce
            your entry.
            <br />
            <br />
            c) waive any moral rights or similar rights you have in your entry
            whether such rights arise pursuant to the Copyright, Designs and
            Patent Act 1988 (the “Act”) or equivalent legislation anywhere in
            the world.
            <br />
            <br />
            d) warrant to Farewill that, where applicable, the promotion entry
            is wholly original to you and not wholly or substantially copied
            from any other material and that the promotion entry does not
            defame, cause injury to or invade the privacy of or otherwise
            infringe or violate any statutory, common law, regulatory or
            intellectual property rights of another.
          </li>
        </ol>
      </li>
      <li>
        <H size="XS" tag="h5">
          Winners
        </H>
        <ol>
          <li>
            The winning entry will be chosen at random from all correct
            responses which meet the entry criteria. Farewill may declare an
            entry void if it does not reach a required standard, in which case
            we may decide to award prizes or not as appropriate.
          </li>
          <li>
            Farewill ensure that promotions and votes are conducted in a way
            that is honest, open, fair and legal; winners of promotions and
            votes are genuine and never invented, pre-chosen or planted. Every
            entry should have a fair chance of winning; we will never ask anyone
            to pose as a promotion contestant or winner; prizes are described
            accurately; and we will not mislead entrants about the nature of a
            prize and prize winners will receive their prizes in reasonable
            time.
          </li>
          <li>
            We may need to verify winners and may request additional information
            (such as proof of age or identity) to be provided to us, or we need
            additional information for another reason (for example, to send you
            the prize). If you do not provide the requested information within
            the specified period, or we receive a notification that our request
            is undeliverable, this will disqualify you as a winner and an
            alternative winner will be selected.
          </li>
          <li>
            As a winner you:
            <br />
            a) grant Farewill the right to publish your winning entry (such as
            submissions of photos, videos and text) and give us your full
            co-operation in connection with your entry, the promotion and
            prizes;
            <br />
            <br />
            b) understand the receipt by you of any prize is conditional upon
            you complying with all applicable laws, rules and regulations;
            <br />
            <br />
            c) agree that to the fullest extent permitted by law, neither
            Farewill, any promotion sponsor(s) or their employees (or equivalent
            workers) shall have any liability to you in connection with your use
            and possession of your prize; and
            <br />
            <br />
            d) understand that that all prize details are subject to
            availability. The prize is awarded in its current state (where
            applicable). Farewill is not responsible for any visible or hidden
            defects in the prize nor for any damage during delivery (shipping)
            of any prize.
          </li>
          <li>
            If any winner of a promotion is unable to take up a prize for any
            reason, or if we have not been able, after making reasonable
            efforts, to make contact with the winner, we may award that prize
            (without notice to the first winner chosen) to an alternative
            winner, in which case the first winner chosen shall not be eligible
            for any share of the prize whatsoever. The Company shall not have
            any liability in such eventuality.
          </li>
          <li>
            Where requested, we will make available information that indicates
            that a valid award of a prize took place. We will do this by sending
            the surname and county of major prize winners and, if applicable,
            copies of winning entries, to anyone who emails{' '}
            <a href="mailto:hello@farewill.com">hello@farewill.com</a> within
            one month after the closing date of a promotion. If you object to
            any or all of your surname, county and winning entry being published
            or made available for these purposes, please contact us at{' '}
            <a href="mailto:hello@farewill.com">hello@farewill.com</a> (note
            that where this is the case we may still be required to provide the
            information and winning entry to the Advertising Standards Authority
            for compliance purposes).
          </li>
        </ol>
      </li>
      <li>
        <H size="XS" tag="h5">
          How we use your information
        </H>
        <ol>
          <li>
            When you provide your information, we may use it to:
            <br />
            a) conduct the competition, acknowledge your entry and notify you
            have won (if you win)
            <br />
            <br />
            b) (if you win) in publicity relating to the promotion;
            <br />
            <br />
            c) to verify the identity of winners and provide the prize verified
            winners. We only keep identity documents long enough to confirm your
            identify, then the copy is securely destroyed;
            <br />
            <br />
            d) to contact you about other promotions Farewill have and to tell
            you more about our services. These communications always contain an
            unsubscribe link so you can ask us to stop contacting you; and
            <br />
            <br />
            e) review uptake and interaction with our promotion so we can
            optimise and improve our offering in the future.
          </li>
          <li>
            To find out more about what information Farewill collects, what we
            use it for and who we share it with, please read our{' '}
            <Link to="/privacy">privacy policy</Link>.
          </li>
        </ol>
      </li>
      <li>
        <H size="XS" tag="h5">
          Miscellaneous
        </H>
        <ol>
          <li>
            We exclude liability to the extent permitted by law for any cost,
            claims or losses howsoever caused that arise by reason of any
            person&apos;s entry into a promotion or the award, or non-award, to
            them of a prize.
          </li>

          <li>
            We may cancel or alter or amend the promotion at any stage, if
            deemed necessary in our opinion, or if circumstances arise outside
            of our control, such as changing the rules of a promotion whilst it
            is running or extending the closing date.
          </li>
        </ol>
      </li>
    </ol>
  </StyledTCsContent>
)

const TCs = () => {
  const TermsAndConditionsContent = [
    {
      id: 'tcs',
      title: 'T&Cs',
      content: <TCsContent />,
    },
  ]

  return <QuestionList questions={TermsAndConditionsContent} />
}

export default TCs

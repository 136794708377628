import React, { ReactElement, useEffect } from 'react'
import { Checkbox as UICheckbox } from '@farewill/ui'

import { useFormContext } from '../index'

type CheckboxField = {
  label: string
  name: string
  required?: boolean
  validate?: (value: any) => string | null
  className?: string
}

const Checkbox = ({
  label,
  name,
  required,
  validate,
  className,
}: CheckboxField): ReactElement => {
  const { errors, touched, setTouched, handleChange, setFieldError, values } =
    useFormContext()
  const currentValue = values[name]
  const error = errors[name]
  const isTouched = touched[name]

  const getError = (value: string) => {
    if (required && !value) return 'This field is required.'
    return validate?.(value)
  }

  useEffect(() => {
    setFieldError(name, getError(currentValue))
  }, [currentValue])

  return (
    <UICheckbox
      className={className}
      onBlur={() => setTouched({ ...touched, [name]: true })}
      name={name}
      checked={currentValue}
      onChange={handleChange}
      value={currentValue}
      label={label}
      error={isTouched && error}
      required={required}
    />
  )
}

export default Checkbox

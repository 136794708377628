import React from 'react'
import styled, { css } from 'styled-components'
import { Image, Wrapper } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import AspectRatioWrapper from 'components/AspectRatioWrapper'
import {
  CAROUSEL_IMAGE_WIDTH,
  CAROUSEL_IMAGE_WIDTH_FROM_M,
  CAROUSEL_MARGIN_FROM_M,
} from '../../../constants'
import { CarouselImage } from '../interfaces'

const ImagesWrapper = styled(Wrapper)<{ numberOfImages: number }>`
  position: relative;
  width: fit-content;
  display: flex;
  transition: transform 0.8s;

  & > * {
    margin-left: ${GTR.M};
  }

  ${screenMin.m`
    & > * {
      margin-left: ${GTR.L};
    }
  `}

  & > *:first-child {
    margin-left: 0;
  }

  ${({ numberOfImages }) => css`
    // Set images wrapper to fit content in IE11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: ${
        CAROUSEL_IMAGE_WIDTH_FROM_M * numberOfImages +
        CAROUSEL_MARGIN_FROM_M * (numberOfImages - 1)
      }px;
  `}
`

const RatioContainer = styled.div<{ width: number; widthFromM: number }>`
  scroll-snap-align: center;

  ${({ width, widthFromM }) =>
    css`
      width: ${width}px;

      ${screenMin.m`
        width: ${widthFromM}px;
      `}
    `}
`

interface CarouselImageProps {
  images: CarouselImage[]
  handleClick?: (index: number) => void
}

const CarouselImages = ({
  images,
  handleClick,
}: CarouselImageProps): React.ReactElement => (
  <ImagesWrapper id="images-wrapper" numberOfImages={images.length}>
    {images.map((image, index) => (
      <RatioContainer
        key={image.path}
        width={CAROUSEL_IMAGE_WIDTH}
        widthFromM={CAROUSEL_IMAGE_WIDTH_FROM_M}
        onClick={handleClick ? () => handleClick(index) : undefined}
      >
        <AspectRatioWrapper ratio={image.width / image.height}>
          <Image
            path={image.path}
            alt={image.alt}
            width={CAROUSEL_IMAGE_WIDTH}
            widthFromM={CAROUSEL_IMAGE_WIDTH_FROM_M}
            loading="auto"
          />
        </AspectRatioWrapper>
      </RatioContainer>
    ))}
  </ImagesWrapper>
)

export default CarouselImages

import React from 'react'
import { COLOR } from '@farewill/ui/tokens'
import { Wrapper } from '@farewill/ui'

import DefaultLayout from 'layouts/DefaultLayout'
import FAREWILL_JSONLD from 'lib/json-ld/farewill'
import JSONLD from 'components/JSONLD'

import AboutUs from './components/AboutUs'
import Banner from './components/Banner'
import TCs from './components/TCs'
import Video from './components/Video'
import AuthorBio from './components/AuthorBio'
import Form from './components/Form'
import ContentBox from './components/ContentBox'
import PhotoSection from './components/PhotoSection'
import Quote from './components/Quote'
import Cards from './components/Cards'

const JohnBooth = () => (
  <>
    <JSONLD data={FAREWILL_JSONLD} />
    <DefaultLayout
      title="John Booth: being remembered, your way"
      description="We’re partnering with ceramic artist John Booth, to explore how urns can reflect who we really are."
      headerColor={COLOR.WHITE}
    >
      <Wrapper>
        <Banner />
      </Wrapper>
      <Wrapper margin={[0, 0, 'L', 0]}>
        <PhotoSection />
      </Wrapper>
      <Wrapper container paddingFromL="XL">
        <ContentBox />
      </Wrapper>
      <Wrapper margin={['L', 0, 0]}>
        <Quote />
      </Wrapper>
      <Wrapper margin={[0, 0, 'XL']}>
        <Form />
      </Wrapper>
      <Wrapper container>
        <Video />
      </Wrapper>
      <Wrapper container>
        <AuthorBio />
      </Wrapper>
      <Wrapper margin={['XL', 0, 0]}>
        <Cards />
      </Wrapper>
      <Wrapper container>
        <TCs />
      </Wrapper>
      <Wrapper>
        <AboutUs />
      </Wrapper>
    </DefaultLayout>
  </>
)

export default JohnBooth

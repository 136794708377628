/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import iconPresets from './presets'

const StyledIcon = styled.svg`
  fill: currentColor;
`

interface IconProps {
  width?: number | string
  height?: number | string
  viewBox: string
  title?: string
  children: React.ReactNode
  className?: string
}

interface IconPresetsProps {
  title?: string
  back?: boolean
  width?: number | string
  height?: number | string
}

type IconType = React.FC<IconProps> & {
  [Property in keyof typeof iconPresets]: React.FC<IconPresetsProps>
}

const IconBase = ({
  width,
  height,
  viewBox,
  children,
  title,
  ...props
}: IconProps): React.ReactElement => (
  <StyledIcon
    width={width || (!height && '100%') || height}
    height={height}
    viewBox={viewBox}
    aria-hidden={title ? true : undefined}
    {...props}
  >
    {children}
  </StyledIcon>
)

const Icon = IconBase as IconType

Object.entries(iconPresets).forEach(([key, preset]) => {
  const PresetIcon = ({
    title,
    width,
    height,
    ...props
  }: IconPresetsProps): React.ReactElement => (
    <Icon viewBox={preset.viewBox} width={width} height={height} {...props}>
      <g>
        {title && <title>{title}</title>}
        {preset.paths}
      </g>
    </Icon>
  )

  Icon[key as keyof typeof iconPresets] = PresetIcon
})

export default Icon

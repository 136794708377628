import React from 'react'
import styled from 'styled-components'
import { Grid, H, P, BackgroundImageWrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'
import MailchimpForm from './MailchimpForm'

const StyledFormWrapper = styled(Grid.Item)`
  background: #f7dadd;

  label {
    color: ${COLOR.BLACK};
  }
`

const StyledBackgroundImageWrapper = styled(BackgroundImageWrapper)`
  ${screenMax.m`
    background-position: top right;
    background-size: 150%;
    background-repeat: repeat-y;
  `}
`

const IS_COMPETITION_ACTIVE = false

const Form = () => (
  <StyledBackgroundImageWrapper
    imagePath="com-assets/john-booth-bg"
    ext="jpg"
    imageWidth={600}
    imageWidthFromL={800}
    imageWidthFromXL={1200}
    cover
    padding={['M', 0]}
    paddingFromM={['XXL', 0]}
  >
    <Grid container padding={['XXL', 0]}>
      <StyledFormWrapper
        spanFromXL={8}
        startColumnFromXL={3}
        padding={['XL', 'M']}
        paddingFromM={['XL', 'XXL']}
      >
        <Grid>
          <Grid.Item spanFromXL={10} startColumnFromXL={2} centered>
            <H tag="h2" size="M">
              Apply for an urn
            </H>
            <P color={COLOR.BLACK}>
              We’re excited to be giving away all five urns. Enter your details
              if you’d like the chance to keep one of these original artworks.
            </P>
          </Grid.Item>
          {IS_COMPETITION_ACTIVE ? (
            <Grid.Item spanFromXL={10} startColumnFromXL={2}>
              <MailchimpForm />
            </Grid.Item>
          ) : (
            <Grid.Item spanFromXL={10} startColumnFromXL={2} centered>
              <H tag="h2" size="S">
                This competition has now closed
              </H>
            </Grid.Item>
          )}
        </Grid>
      </StyledFormWrapper>
    </Grid>
  </StyledBackgroundImageWrapper>
)

export default Form

export enum FORM_FIELDS {
  EMAIL = 'EMAIL',
  NAME = 'FNAME',
  MARKETING_CONSENT = 'FMARKETING',
  BOT = 'b_e454cf05f34366f67963cbc11_6d0029babe',
}

// If you copy url from Mailchipm, change /post? to /post-json
export const MAILCHIMP_URL =
  'https://farewill.us11.list-manage.com/subscribe/post-json?u=e454cf05f34366f67963cbc11&amp;id=6d0029babe'

export const carouselImages = [
  {
    path: 'john-booth-campaign/urn-1',
    alt: '',
    width: 469,
    height: 473,
  },
  {
    path: 'john-booth-campaign/urn-2',
    alt: '',
    width: 469,
    height: 473,
  },
  {
    path: 'john-booth-campaign/urn-3',
    alt: '',
    width: 469,
    height: 473,
  },
  {
    path: 'john-booth-campaign/urn-4',
    alt: '',
    width: 469,
    height: 473,
  },
  {
    path: 'john-booth-campaign/urn-5',
    alt: '',
    width: 469,
    height: 473,
  },
]

export const CAROUSEL_IMAGE_WIDTH = 240
export const CAROUSEL_IMAGE_WIDTH_FROM_M = 480
export const CAROUSEL_MARGIN_FROM_M = 48
export const CAROUSEL_MARGIN = 24

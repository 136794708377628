import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR, BORDER_RADIUS } from '@farewill/ui/tokens'

const ErrorBlock = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};
  margin-top: ${GTR.M};
  border: 2px solid ${COLOR.STATE.ERROR};
  border-radius: ${BORDER_RADIUS.S};
`

export default ErrorBlock

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BackgroundImageWrapper, Grid } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import Card from './Card'

const Cards = () => {
  const { article, guide, blogImage } = useStaticQuery(graphql`
    query {
      article: contentfulArticle(slug: { eq: "being-remembered-your-way" }) {
        ...ArticleCardFields
      }
      guide: contentfulGuide(slug: { eq: "how-to-arrange-a-cremation" }) {
        ...GuideCardFields
      }
      blogImage: allContentfulAsset(
        filter: { contentful_id: { eq: "5OgftMkhqYW16qtyETuA9W" } }
      ) {
        edges {
          node {
            file {
              url
            }
          }
        }
      }
    }
  `)
  const blogImageURL = blogImage.edges[0].node
  return (
    <BackgroundImageWrapper
      backgroundColor={COLOR.ACCENT.PRIMARY}
      padding={['XL', 0]}
    >
      <Grid container>
        <Grid.Item spanFromL={4} key={article.id}>
          <Card
            title="How to choose an urn"
            link={`/blog/${article.slug}`}
            image={blogImageURL}
            readMore="Read article"
          />
        </Grid.Item>
        <Grid.Item spanFromL={4} key={guide.id}>
          <Card
            title={guide.title}
            link={`/guides/${guide.slug}`}
            image={guide.image}
            readMore="Read guide"
          />
        </Grid.Item>
        <Grid.Item spanFromL={4} key={guide.id}>
          <Card
            title="What to do when someone dies"
            link="/what-to-do-when-someone-dies"
            image="/illustrations/humanist-ceremony-wide"
            readMore="Read more"
          />
        </Grid.Item>
      </Grid>
    </BackgroundImageWrapper>
  )
}

export default Cards

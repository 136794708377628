import React from 'react'
import styled from 'styled-components'
import { Grid, P, Wrapper } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import SimpleQuote from 'components/SimpleQuote'

const StyledWrapper = styled(Wrapper)`
  background: #b8cae6;
`

const StyledSimpleQuote = styled(SimpleQuote)`
  color: ${COLOR.BLACK};
  font-size: ${FONT.SIZE.L};
`

const StyledAuthor = styled(P)`
  text-transform: uppercase;
  font-weight: ${FONT.WEIGHT.BOLD};
`

const Quote = () => (
  <StyledWrapper paddingFromXL={['M', 0]}>
    <Grid container paddingFromM={['L']} paddingFromL={['L', 'XL']}>
      <Grid.Item startColumnFromL={3} spanFromL={8} centered>
        <StyledSimpleQuote centered>
          I’ve been really inspired by the opportunity to create something new
          in an industry traditionally void of creativity and choice. After the
          year we’ve had, it’s more important than ever to bring some joy to how
          we’re remembered, creating urns which reflect the vibrancy of our
          lives.
        </StyledSimpleQuote>
        <StyledAuthor color={COLOR.BLACK} size="S" margin={['L', 0, 0]}>
          John Booth
        </StyledAuthor>
      </Grid.Item>
    </Grid>
  </StyledWrapper>
)

export default Quote

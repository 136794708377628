import React from 'react'
import styled from 'styled-components'
import { Grid, H, P, Image, Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import LinkButton from 'components/LinkButton'

const StyledImage = styled(Image)`
  ${screenMax.m`
    transform: translateX(-100px);
  `}
`

const ContentBox = () => (
  <Grid>
    <Grid.Item spanFromL={5} paddingFromL={[0, 'L', 0, 0]}>
      <H tag="h2" size="L" decorative>
        Talking about death to break the taboo
      </H>

      <StyledImage
        path="textures/paint-splash"
        ext="svg"
        width={500}
        widthFromL={600}
        stretch
      />
    </Grid.Item>
    <Grid.Item spanFromL={6}>
      <P>
        Death is hard. Whether someone you love dies, or you’re facing up to
        your own mortality, it’s a stressful process. That’s why so many people
        do not talk about what happens once they’re gone, or find it difficult
        to manage the long, complex admin after a loved one dies. We’re on a
        mission to change the way the world deals with death. That starts with
        talking more about how we want to be remembered, and helping people to
        organise a funeral that reflects who they are.
      </P>
      <P>
        If you’re looking for an urn but are not sure where to start, John’s
        shared more ideas over on our blog.
      </P>
      <Wrapper margin={['XL', 0, 0]}>
        <LinkButton wide to="/blog/being-remembered-your-way">
          Learn more
        </LinkButton>
      </Wrapper>
    </Grid.Item>
  </Grid>
)

export default ContentBox

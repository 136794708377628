import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button, Wrapper, H, P, Image } from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

import LogoSvg from 'assets/logo-icon.svg'
import WrapperWithEdge from 'components/WrapperWithEdge'
import { AWARD_LOGO_URL } from 'lib/awards/constants'
import TrustpilotBanner from 'views/cremation/components/TrustpilotBanner'

const VerticalLayout = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

const Logo = () => (
  <Wrapper maxWidth={46} margin={['0', 'auto', 'M']}>
    <img src={LogoSvg} alt="Farewill logo" width="31px" height="45px" />
  </Wrapper>
)

const StyledCloudWrapper = styled(Wrapper)`
  position: absolute;

  width: 56px;
  height: 36px;

  ${screenMin.m`
    width: 220px;
    height: 110px;
  `}

  top: ${GTR.S};
  right: ${GTR.S};

  ${screenMin.m`
    right: ${GTR.S};
  `}

  ${screenMin.l`
    right: 220px;
  `}
`

const StyledTexture = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: 0;
`

const StyledTopRightCloud = styled(StyledTexture)`
  top: 0;
  right: 0;
  display: none;

  ${screenMin.m`
    display: block;
  `}
`

const StyledBottomLeftCloud = styled(StyledTexture)`
  bottom: 0;
  left: 0;
`

const StyledBackgroundTexture = styled(StyledTexture)`
  bottom: 0;
  left: 0;
  z-index: -1;

  ${screenMax.s`
    display: none;
  `}
`

const Clouds = () => (
  <StyledCloudWrapper>
    <StyledTopRightCloud path="illustrations/cloud-2" width={85} />
    <StyledBottomLeftCloud path="illustrations/cloud-1" width={56} />
  </StyledCloudWrapper>
)

const AboutUs = () => (
  <WrapperWithEdge
    lightAccent
    direction="up"
    edgeBackgroundColor={COLOR.BACKGROUND.FOG}
    thickEdge
  >
    <Wrapper centered maxWidthInColumns={7} margin={[0, 'auto']}>
      <VerticalLayout container centered>
        <Logo />
        <H size="L" tag="h2" decorative>
          A little more about us
        </H>
        <P size="L" color={COLOR.BLACK}>
          At Farewill we’re making everything to do with death easier,
          friendlier and more affordable. We provide funerals, wills and probate
          with a difference, and we’re helping families all over the UK. See the
          services we offer as{' '}
          <Link to="/funerals/north-london">
            funeral directors in North London
          </Link>
          , <Link to="/funerals/brighton">Brighton</Link>, and{' '}
          <Link to="/funerals/cambridge">Cambridge</Link> or see the services we
          offer{' '}
          <Link to="/funerals/areas-covered">
            as funeral directors right across the UK
          </Link>
          .
        </P>
        <Wrapper margin={['L', 0]}>
          <Button.White wide to="/about" tag={Link}>
            Find out more
          </Button.White>
        </Wrapper>
        <Wrapper centered marginFromL={['S', 0]}>
          <TrustpilotBanner
            showLabel
            logos={[
              AWARD_LOGO_URL.MODERN_LAW_AWARDS,
              AWARD_LOGO_URL.BRITISH_WILLS_AND_PROBATE_AWARDS,
            ]}
          />
        </Wrapper>
      </VerticalLayout>
    </Wrapper>
    <Clouds />

    <StyledBackgroundTexture path="textures/rotated/detail-3" width={750} />
  </WrapperWithEdge>
)

export default AboutUs

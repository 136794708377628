import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import { trackEvent } from 'lib/tracking'
import { email as emailValidation } from 'lib/form/validation'
import { Form } from 'components/Form'
import Input from 'components/Form/Input'
import Checkbox from 'components/Form/Checkbox'
import ErrorBlock from 'components/Form/ErrorBlock'
import { FORM_FIELDS } from './constants'
import { IMailchimpData } from './types'
import { subscribeUserToMailchimp } from './helpers'

const StyledHiddenInputWrapper = styled(Grid.Item)`
  position: absolute;
  left: -5000px;
`

const StyledCheckboxWrapper = styled(Grid.Item)`
  label {
    white-space: break-spaces;
  }
`

const StyledP = styled(P)`
  font-size: ${FONT.SIZE.XS};
`

const StyledSuccessWrapper = styled(Wrapper)`
  background-color: ${COLOR.STATE.SUCCESS_10};
`

const ApiErrorMessage = ({ error }: { error: string }): React.ReactElement => (
  <ErrorBlock>
    {error ? (
      <P
        size="S"
        strong
        dangerouslySetInnerHTML={{
          __html: error,
        }}
      />
    ) : (
      <H tag="h2" size="XS">
        Sorry, something went wrong.
      </H>
    )}
  </ErrorBlock>
)

const SuccessMessage = ({
  message,
}: {
  message: string
}): React.ReactElement => (
  <StyledSuccessWrapper padding="M" centered>
    <P
      size="M"
      color={COLOR.BLACK}
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  </StyledSuccessWrapper>
)

const initialValues = {
  [FORM_FIELDS.EMAIL]: '',
  [FORM_FIELDS.NAME]: '',
  [FORM_FIELDS.MARKETING_CONSENT]: false,
  [FORM_FIELDS.BOT]: '',
}

const MailchimpForm = (): React.ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasApiError, setHasApiError] = useState(false)
  const [apiError, setApiError] = useState('')
  const [apiSuccess, setApiSuccess] = useState('')

  const handleSubmit = async (data: IMailchimpData) => {
    setIsSubmitting(true)

    try {
      setHasApiError(false)
      const { result, msg } = await subscribeUserToMailchimp(data)
      if (result === 'error') throw new Error(msg)
      if (result === 'success') {
        trackEvent('John Booth competition form submission', 'submit')
        setApiSuccess(
          'Thank you for your entry! Please check your inbox for a confirmation email.'
        )
      }
    } catch (error) {
      if (error instanceof Error && error.message) {
        setApiError(error.message)
      }
      setHasApiError(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {apiSuccess.length > 0 ? (
        <SuccessMessage message={apiSuccess} />
      ) : (
        <Grid>
          <Grid.Item>
            <Input name={FORM_FIELDS.NAME} label="Full name" required />
          </Grid.Item>
          <Grid.Item>
            <Input
              name={FORM_FIELDS.EMAIL}
              label="Email"
              validate={emailValidation}
              required
            />
          </Grid.Item>
          <StyledHiddenInputWrapper aria-hidden="true">
            <Input name={FORM_FIELDS.BOT} />
          </StyledHiddenInputWrapper>
          <StyledCheckboxWrapper>
            <Checkbox
              name={FORM_FIELDS.MARKETING_CONSENT}
              label="I’d like to get updates from Farewill, including news, research and offers"
            />
          </StyledCheckboxWrapper>
          {hasApiError && (
            <Grid.Item>
              <ApiErrorMessage error={apiError} />
            </Grid.Item>
          )}
          <Grid.Item>
            <Button.Secondary stretch loading={isSubmitting}>
              Apply
            </Button.Secondary>
          </Grid.Item>
          <Grid.Item>
            <StyledP>
              Entries must be received by midnight on 17<sup>th</sup> May to be
              included in this draw. We’ll choose 5 people at random and contact
              them using the details provided. We won’t share your details with
              anyone else. By submitting your details, you agree to our
              promotion terms. Our privacy policy contains more detail on how we
              use your information.
            </StyledP>
          </Grid.Item>
        </Grid>
      )}
    </Form>
  )
}

export default MailchimpForm

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BREAKPOINT } from '@farewill/ui/tokens'
import { Wrapper } from '@farewill/ui'

import { CarouselImage } from './interfaces'
import CarouselImages from './components/CarouselImages'
import CarouselArrows from './components/CarouselArrows'
import {
  CAROUSEL_IMAGE_WIDTH_FROM_M,
  CAROUSEL_MARGIN_FROM_M,
} from '../../constants'

const ScrollCarouselWrapper = styled(Wrapper)`
  width: 100%;
  position: relative;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
`

const ArrowsCarouselWrapper = styled(Wrapper)`
  width: 100%;
  position: relative;
  overflow: hidden;
`

const Carousel = ({
  images,
}: {
  images: CarouselImage[]
}): React.ReactElement => {
  const [windowWidth, setWindowWidth] = useState<number>()
  const [focusedIndex, setFocusedIndex] = useState(0)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const setImageIndex = (index: number) => {
    if (index >= 0 && index <= images.length - 1) {
      setFocusedIndex(index)
    }
  }

  const calculateImagePosition = (index: number): number => {
    if (index === 1) {
      return CAROUSEL_IMAGE_WIDTH_FROM_M * 1.5 + CAROUSEL_MARGIN_FROM_M
    }
    if (index > 1) {
      return (
        calculateImagePosition(index - 1) +
        CAROUSEL_IMAGE_WIDTH_FROM_M +
        CAROUSEL_MARGIN_FROM_M
      )
    }
    return 0
  }

  useEffect(() => {
    const carousel = document.getElementById('carousel')
    const imagesWrapper = document.getElementById('images-wrapper')

    if (carousel && imagesWrapper) {
      const calculatedPosition =
        calculateImagePosition(focusedIndex) - carousel.offsetWidth / 2
      const maxCarouselPosition =
        imagesWrapper.offsetWidth - carousel.offsetWidth

      let focusedPosition = calculatedPosition
      if (calculatedPosition < 0) {
        focusedPosition = 0
      } else if (calculatedPosition > maxCarouselPosition) {
        focusedPosition = maxCarouselPosition
      }

      imagesWrapper.style.transform = `translate(${-focusedPosition}px)`
    }
  }, [focusedIndex])

  return windowWidth && windowWidth > BREAKPOINT.M ? (
    <ArrowsCarouselWrapper id="carousel">
      <CarouselArrows
        index={focusedIndex}
        maxIndex={images.length - 1}
        handleClick={setImageIndex}
      />
      <CarouselImages images={images} handleClick={setImageIndex} />
    </ArrowsCarouselWrapper>
  ) : (
    <ScrollCarouselWrapper>
      <CarouselImages images={images} />
    </ScrollCarouselWrapper>
  )
}

export default Carousel

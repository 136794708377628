import React from 'react'
import styled from 'styled-components'
import { Grid, H, Image, P, Wrapper } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

import { carouselImages } from '../constants'
import Carousel from './Carousel/Index'

const StyledWrapper = styled(Wrapper)`
  z-index: 1;
  position: relative;
  overflow: hidden;
`

const StyledTextureLeft = styled(Image)`
  z-index: -1;
  position: absolute;
  pointer-events: none;
  left: -5%;
  top: 33%;
  ${screenMin.m`
  left: -4%;
  top: 15%;
  `}
  ${screenMin.l`
  left: -2%;
  `}
`

const StyledTextureRight = styled(Image)`
  z-index: -1;
  position: absolute;
  pointer-events: none;
  top: 0%;
  right: 0%;
  ${screenMin.m`
    right: 0%;
    top: 5%;
  `}
  ${screenMin.l`
    top: 8%;
    right: 0%;
  `}
`

const StyledTextureBottom = styled(Image)`
  ${screenMax.s`
    display: none;
  `}
  z-index: -1;
  position: absolute;
  pointer-events: none;
  bottom: 5%;
  right: 0%;
`

const StyledP = styled(P)`
  text-align: left;
  color: ${COLOR.BLACK};
`

const StyledH1 = styled(H)`
  ${screenMax.m`
    font-size: ${FONT.SIZE.XL}
  `}
`

const StyledSubtitle = styled(P)`
  color: ${COLOR.BLACK};
  ${screenMax.m`
    font-size: ${FONT.SIZE.M}
  `}
`

const StyledH2 = styled(H)`
  ${screenMax.m`
    font-size: ${FONT.SIZE.L}
  `}
`

const StyledGridItem = styled(Grid.Item)`
  width: 100%;
`

const Banner = (): React.ReactElement => (
  <StyledWrapper background={COLOR.ACCENT.PRIMARY}>
    <StyledTextureLeft
      path="textures/yellow-vertical-stroke"
      width={100}
      widthFromL={125}
      loading="auto"
    />
    <StyledTextureRight
      path="textures/half-moon-spray"
      width={80}
      widthFromM={125}
      loading="auto"
    />
    <StyledTextureBottom
      path="textures/yellow-horizontal-stroke"
      width={400}
      widthFromL={600}
      loading="auto"
    />
    <Grid container centered padding={['XL', 'M']}>
      <Grid.Item>
        <Image
          path="internal-logos/farewill-x-john-booth"
          width={250}
          widthFromM={300}
          stretch
        />
      </Grid.Item>

      <Grid.Item maxWidth={560} margin={[0, 'auto', 'XS']}>
        <StyledH1 size="XL" decorative tag="h1">
          Being remembered your way matters
        </StyledH1>

        <StyledSubtitle size="L" color={COLOR.BLACK}>
          So we’re partnering with ceramic artist John Booth, to explore how
          urns can reflect who we really are.
        </StyledSubtitle>
      </Grid.Item>

      <StyledGridItem margin={['M', 0]} marginFromM={0}>
        <Carousel images={carouselImages} />
      </StyledGridItem>

      <Grid.Item spanFromL={4} startColumnFromL={2}>
        <StyledH2 size="L" decorative tag="h2">
          Redesigning how we approach death
        </StyledH2>
      </Grid.Item>

      <Grid.Item
        spanFromL={6}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <StyledP>
          Many people choose an urn as their final resting place. But we rarely
          talk about how they can help us express who we are. For John, this
          meant creating five colourful urns to start a conversation about how
          we can better honour someone’s life, their way. If these urns resonate
          with you, scroll down to find out how you can apply for one.
        </StyledP>
      </Grid.Item>
    </Grid>
  </StyledWrapper>
)

export default Banner

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Wrapper, H, Image, ArrowRightIcon } from '@farewill/ui'
import { GTR, COLOR, BORDER } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'

import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

const StyledH = styled(H)`
  margin-bottom: ${GTR.S};
`
const StyledSubH = styled(H)`
  color: ${COLOR.BLACK};
`

const StyledCard = styled(Link)`
  display: block;
  overflow: hidden;
  height: 100%;
  background: ${COLOR.WHITE};
  border-radius: ${BORDER.RADIUS.M};
  box-shadow: ${BORDER.SHADOW.M};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:hover ${StyledH} {
    text-decoration: underline;
  }

  &:hover ${StyledSubH} {
    text-decoration: none;
  }

  ${screenMin.m`
    height: 385px;
  `}
`

const StyledIconArrow = styled(ArrowRightIcon)`
  margin-left: ${GTR.XS};
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  min-height: 160px;

  ${screenMin.m`
    flex-direction: column;
  `};
`

const StyledWrapperItemOne = styled(Wrapper)`
  align-self: center;
  justify-self: center;
  position: relative;
  width: 50%;
  padding: ${GTR.M};

  ${screenMin.m`
    width: 100%;
  `};
`

const StyledWrapperItemTwo = styled(Wrapper)`
  align-self: center;
  justify-self: center;
  width: 50%;
  user-select: none;

  ${screenMax.l`
    display: flex;
    justify-content: center;
  `}

  ${screenMin.m`
    width: 100%;
  `};
`

const StyledImage = styled(Image)`
  ${screenMin.m`
    margin-bottom: ${GTR.L};
  `};
`

const GuideCard = ({ title, image, link, readMore }) => (
  <StyledCard to={`${link}`}>
    <StyledWrapper>
      <StyledWrapperItemOne>
        <StyledH size="M" tag="h3" decorative>
          {title}
        </StyledH>
        <StyledSubH context tag="h4">
          {readMore}
          <StyledIconArrow size="S" inline />
        </StyledSubH>
      </StyledWrapperItemOne>
      <StyledWrapperItemTwo>
        {image && image.file ? (
          <StyledImage
            path={image.file.url}
            formatPath={({ path, width }) =>
              `${path}?w=${width}&h=${Math.floor(width * 0.45)}&fit=pad`
            }
            width={400}
            stretch
          />
        ) : (
          <StyledImage
            path={image}
            width={600}
            formatPath={({ path, width }) =>
              `${CLOUDINARY_ROOT_PATH}/c_pad,f_auto,q_auto,w_${width}/${path}.png`
            }
            stretch
          />
        )}
      </StyledWrapperItemTwo>
    </StyledWrapper>
  </StyledCard>
)

GuideCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  link: PropTypes.string.isRequired,
  readMore: PropTypes.string,
}

GuideCard.defaultProps = {
  readMore: 'Read more',
}

export default GuideCard

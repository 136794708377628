import { Grid, H, Image, P } from '@farewill/ui'
import { BORDER, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import React from 'react'
import styled from 'styled-components'
import Icon from 'components/Icon'

const StyledGrid = styled(Grid)`
  ${screenMin.m`
    border: 1px solid ${COLOR.GREY.LIGHT};
    border-radius: ${BORDER.RADIUS.M};
  `}
`

const StyledImage = styled(Image)`
  ${screenMin.m`
    position: absolute;
    top: -1px;
    left: -1px;
    right: 0;
    bottom: -1px;
    height: calc(100% + 2px);
    object-fit: cover;
    border-top-left-radius: ${BORDER.RADIUS.M};
    border-bottom-left-radius: ${BORDER.RADIUS.M};
    z-index: 2;
  `}
`

const StyledListItem = styled.li`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${GTR.S};
  }

  & + li {
    margin-top: ${GTR.S};
  }
`

const StyledList = styled.ul`
  margin-top: ${GTR.L};
`

const StyledContent = styled(Grid.Item)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledImageWrapper = styled(Grid.Item)`
  position: relative;
`

const AuthorBio = () => (
  <StyledGrid>
    <StyledImageWrapper spanFromM={5}>
      <StyledImage
        path="john-booth-campaign/FarewillUrnsOvers_046_mufgge"
        ext="jpg"
        width={600}
        stretch
      />
    </StyledImageWrapper>
    <StyledContent spanFromM={7} paddingFromM="S" paddingFromL="XL">
      <H tag="h2" size="L" decorative>
        John Booth
      </H>
      <P>
        John is a London–based artist, illustrator, ceramicist and textile
        designer, known for his graphic works featuring multi-layered collages
        of textures and colours.
      </P>
      <P>
        Born in Scotland and raised in Cumbria, Booth moved to London in 2004,
        before graduating with a BA in Fashion Print Design at Central Saint
        Martins in 2009. His ceramics have a distinctive style and reflect the
        vibrancy and boldness of his outlook on the world.
      </P>
      <StyledList>
        <StyledListItem>
          <Icon.Instagram width={24} />
          <a
            href="https://www.instagram.com/john_booth"
            target="_blank"
            rel="noreferrer"
          >
            @john_booth
          </a>
        </StyledListItem>
        <StyledListItem>
          <Icon.Website width={24} />
          <a href="http://john-booth.com" target="_blank" rel="noreferrer">
            john-booth.com
          </a>
        </StyledListItem>
      </StyledList>
    </StyledContent>
  </StyledGrid>
)

export default AuthorBio

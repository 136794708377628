import React from 'react'
import styled from 'styled-components'
import { Image, Wrapper } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'

const StyledWrapper = styled(Wrapper)`
  max-height: 300px;

  img {
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: cover;
  }

  ${screenMin.m`
    max-height: 400px;

    img {
      max-height: 400px;
    }
  `}

  ${screenMin.l`
    max-height: 550px;

    img {
      max-height: 550px;
    }
  `}
`

const PhotoSection = () => (
  <StyledWrapper>
    <Image
      path="john-booth-campaign/FarewillUrnsOvers_187_lrwjja"
      ext="jpg"
      width={400}
      widthFromXL={1200}
    />
  </StyledWrapper>
)

export default PhotoSection
